import { useQuery } from "react-query";
import { BLOGS_ENDPOINT } from "../endpoint_types";

import { fetchHandler } from "../fetchHandler";
import { useTranslation } from "next-i18next";

export default function useBlogs() {
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(["blogs", language], () =>
    fetchHandler({
      locale: language,
      to: BLOGS_ENDPOINT,
      dataName: "data",
    })
  );

  // return useQuery(
  //   [
  //     "blogs",
  //     {
  //       to: BLOGS_ENDPOINT,
  //       locale: language,
  //       dataName: "data",
  //     },
  //   ],
  //   fetchHandler
  // );
}
