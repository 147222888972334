import React from 'react';
import { useToggle } from '../../../hooks/useToggle';

import Image from 'next/image';
import classNames from 'classnames';

export const QAItem = ({ title, text }) => {
  const [showAnswer, toggle] = useToggle(false);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggle}>
        <h4 className="accordion__title">{title}</h4>

        <div className="accordion__btn">
          {showAnswer ? (
            <Image src="/imgs/close.svg" alt="close" width="12" height="12" />
          ) : (
            <Image
              src="/imgs/down_arrow.svg"
              alt="arrow"
              width={14}
              height={14}
            />
          )}
        </div>
      </div>
      <div
        className={classNames('accordion-content', {
          'accordion-content--active': showAnswer,
        })}
      >
        <div className="accordion__text">{text}</div>
      </div>
    </div>
  );
};
