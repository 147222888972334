import React from "react";

import { useTranslation } from "react-i18next";
import { AppLinkBlock } from "../../components/AppLinkBlock";
import Image from "next/image";

export const BannerSection = ({ image }) => {
  const [t, { language }] = useTranslation();

  return (
    <section className="main-banner">
      <div className="main-banner-container container">
        <div className="main-banner-inner">
          {/* <img
            className="main-banner__phone"
            src={`/imgs/phone_ua.jpg`}
            alt="image"
          /> */}
          <div className="main-banner__phone">
            <Image
              src={image}
              priority={true}
              layout="fixed"
              alt="image"
              // objectFit="cover"
              width={252}
              height={545}
              className="main-banner__phone-img"
            />
          </div>
          <div className="main-banner__content ">
            <div className="main-banner__content-inner">
              <h1 className="main-banner__title">{t("header_title")}</h1>
              <h2 className="main-banner__subtitle">{t("passive_income")}</h2>
              <p className="main-banner__text subtitle">
                {t("start_investing")}
              </p>
              <AppLinkBlock />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
