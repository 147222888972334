import { BannerSection } from "../routesComponents/BondPage/BannerSection";
// import { AboutSection } from "../routesComponents/BondPage/AboutSection/AboutSection";

// import { QuoteSection } from "../routesComponents/BondPage/QuoteSection/QuoteSection";
// import { DemoSection } from "../routesComponents/BondPage/DemoSection/DemoSection";

// import { QASection } from "../routesComponents/BondPage/QASection/QASection";
import { withConfig } from "../utils/withConfig";
import { dehydrate, QueryClient } from "react-query";
import { fetchHandler } from "../rq/fetchHandler";
import {
  BLOGS_ENDPOINT,
  // BONDS_ENDPOINT,
  // QA_BOND_ENDPOINT,
  // VIDEOS_ENDPOINT,

  // BOND_ADVANTAGES_ENDPOINT,
  BROKER_ENDPOINT,
  QA_ENDPOINT,
} from "../rq/endpoint_types";
import { BlogPage } from "../routesComponents/BlogPage";
import { SITE_URL } from "../constants";
import { QASection } from "../routesComponents/BondPage/QASection/QASection";

// import { ReasonSection } from "../routesComponents/BondPage/ReasonSection/ReasonSection";
// import { BlogSection } from "../routesComponents/BondPage/BlogSection";

export const getStaticProps = async ({ locale }) => {
  const queryClient = new QueryClient();

  const queries = [
    { to: BLOGS_ENDPOINT, key: "blogs", dataName: "data" },
    { to: QA_ENDPOINT, key: "qa_all" },
    // { to: BONDS_ENDPOINT, key: "bonds" },

    // { to: VIDEOS_ENDPOINT, key: "videos", dataName: "videos" },
    // { to: QA_BOND_ENDPOINT, key: "qa_bond" }, // dataName: "qna_list"
    // {
    //   to: BOND_ADVANTAGES_ENDPOINT,
    //   key: "advantages_bond",
    //   // dataName: "advantages",
    // },
    {
      key: "broker",
      to: BROKER_ENDPOINT,
      params: { key: process.env.API_DEFAULT_KEY },
      dataName: "data",
    },
  ];

  const promiseArr = queries.map(
    ({ key, ...opts }) =>
      queryClient.prefetchQuery([key, locale], () =>
        fetchHandler({ locale, ...opts })
      )
    // queryClient.prefetchQuery([key, { locale, ...opts }], fetchHandler)
  );

  await Promise.all(promiseArr);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      tags: { alternate: `${SITE_URL}/${locale}` },
      image: `/imgs/phone_${locale}.jpg`,
      ...(await withConfig(locale)),
    },
  };
};

export default function Home({ image }) {
  return (
    <>
      <BannerSection image={image} />
      <BlogPage />
      {/* <BlogSection /> */}
      {/* <AboutSection /> */}
      {/* <ReasonSection />
  
      {/* <DemoSection /> */}
      <QASection />

      <style jsx global>{`
        @import "../routesComponents/BlogPage/BlogPage.scss?5";
        @import "../routesComponents/BondPage/BondPage.scss?8";
      `}</style>
    </>
  );
}
