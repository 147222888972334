import React from "react";
import { BlogCard } from "./BlogCard";
import useBlogs from "../../rq/queries/useBlogs";

export const BlogPage = () => {
  const { data: blogs = [] } = useBlogs();
  
  return (
    <div className="news-articles-block">
      <div className="container">
        <div className="news-articles-inner">
          {blogs.map(({ id, slug, ...props }, index) => {
            const href = `/news/${slug}`;

            return <BlogCard key={id} href={href} index={index} {...props} />;
          })}
        </div>
      </div>
    </div>
  );
};
