import React, { memo } from "react";

import Link from "next/link";
import Image from "next/image";
import { MONTH_SHORT_WITH_CAPITAL } from "../../../utils/calendar/calendarUtil";
import { useTranslation } from "next-i18next";

const BlogCard = memo(
  ({
    title,
    preview: subtitle = "",
    date: dateOfPost,
    image,
    index,
    author: { thumbnail, name: authorName, title: authorTitle },
    href,
  }) => {
    const date = new Date(dateOfPost);
    const {
      i18n: { language },
    } = useTranslation();
    
    return (
      <div className="news-card-wrapper">
        <div className={index === 0 ? "first-card" : "another-card"}>
          <Link href={href} passHref>
            <a
              // {...(isLanding && { target: "_blank", rel: "noopener noreferrer" })}
              className="news-img-wrapper"
            >
              {image && (
                <Image
                  layout="fill"
                  objectFit="cover"
                  priority={true}
                  src={image}
                  alt="news-img"
                  // className="news-img"
                />
              )}
            </a>
          </Link>
          <div className="body-of-card">
            <div className="card-block">
              <Link href={href} passHref>
                <a>
                  <h3 className="card-title">{title}</h3>

                  <h4 className="card-subtitle text-muted">{subtitle}</h4>
                </a>
              </Link>
            </div>
            <div className="logo-block">
              {thumbnail && (
                <Image
                  className="logo-img"
                  alt="logo"
                  height={40}
                  width={40}
                  layout="fixed"
                  src={thumbnail}
                  placeholder="empty"
                />
              )}
              <div className="logo-text-block">
                {authorName && <span className="logo-name">{authorName}</span>}

                <span className="logo-data">
                  {`${
                    MONTH_SHORT_WITH_CAPITAL[language][date.getMonth()]
                  } ${date.getDate()}, ${date.getFullYear()} `}
                </span>
              </div>
            </div>
          </div>
        </div>
        <style jsx global>{`
          @import "./BlogCard.scss?1";
        `}</style>
      </div>
    );
  }
);

export { BlogCard };
