import React from "react";
import { useTranslation } from "react-i18next";
import { QAItem } from "./QAItem";

import useQA from "../../../rq/queries/useQA";
import { BondTypes } from "../../../types/bond_types";

export const QASection = ({ bondType = BondTypes.all_type }) => {
  const [t] = useTranslation();

  const { data = [] } = useQA({ bondType });

  return (
    !!data.length && (
      <section className="section-qa section" id="_qa">
        <div className="container">
          <div className="section-qa-inner">
            <div className="section-qa-content section-content">
              <div className="section-label">
                <h3 className="section__title main-title">{t("faq")}</h3>
              </div>
              <div className="qa-list">
                {data
                  .sort((a, b) => a.order - b.order)
                  .map(({ answer, question }, index) => {
                    const text = answer
                      .split("\r\n")
                      .map((str, index) =>
                        str === "" ? (
                          <br key={index} />
                        ) : (
                          <p key={index}> {str}</p>
                        )
                      );
                    return <QAItem key={index} title={question} text={text} />;
                  })}
              </div>
            </div>
          </div>
        </div>
        <style jsx global>{`
          @import "./QAItem.scss?ss";
        `}</style>
      </section>
    )
  );
};
